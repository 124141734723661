<template>
  <div>
    <div class="row q-col-gutter-md">
      <div class="col-12 col-sm-6">
        <BaseInput
          label="Nome"
          error-name="name"
          :errors="errors"
          :rules="[(val) => !!val || 'Campo Obbligatorio']"
          v-model="user.name"
        >
          <template #prepend>
            <q-icon name="mdi-account-circle-outline" />
          </template>
        </BaseInput>
      </div>

      <div class="col-12 col-md-6">
        <BaseInput
          type="email"
          label="Email"
          error-name="email"
          :errors="errors"
          :rules="[(val) => !!val || 'Campo Obbligatorio']"
          v-model="user.email"
        >
          <template #prepend>
            <q-icon name="mdi-email-outline" />
          </template>
        </BaseInput>
      </div>

      <div class="col-12 col-md-6">
        <UserRoleSelect
          label="Ruolo"
          error-name="role_id"
          :errors="errors"
          v-model="user.role"
        >
          <template #prepend>
            <q-icon name="mdi-account-star-outline" />
          </template>
        </UserRoleSelect>
      </div>

      <div class="col-12 col-md-6">
        <BaseInput
          label="Colore"
          v-model="user.color"
          readonly
          error-name="color"
          :errors="errors"
        >
          <template v-slot:prepend>
            <q-icon
              name="mdi-circle"
              size="lg"
              :style="{ color: user.color }"
            />
            <q-icon name="mdi-eyedropper-variant" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-color v-model="user.color" />
              </q-popup-proxy>
            </q-icon>
          </template>
        </BaseInput>
      </div>
    </div>

    <BaseCheckbox v-model="user.active" label="Accesso attivo" />

    <h6 class="q-mt-md q-mb-md">
      <template v-if="isNew">Password</template>
      <q-toggle
        v-else
        label="Modifica password"
        v-model="user.password_update"
      />
    </h6>

    <transition name="from-top">
      <div
        class="row q-col-gutter-md q-mt-sm q-mb-md"
        v-if="showPasswordUpdate"
      >
        <div class="col-12 col-md-6 q-pt-none">
          <BaseInput
            color="primary"
            label="Scegli una password"
            error-name="password"
            :errors="errors"
            v-model="user.password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[(val) => !!val || 'Campo Obbligatorio']"
          >
            <template #prepend>
              <q-icon name="mdi-lock-outline" />
            </template>

            <template #append>
              <q-icon
                :name="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                class="cursor-pointer"
                @click="showPassword = !showPassword"
              />
            </template>
          </BaseInput>
        </div>

        <div class="col-12 col-sm-6 q-pt-none">
          <BaseInput
            color="primary"
            label="Conferma la password"
            error-name="password_confirmation"
            :errors="errors"
            v-model="user.password_confirmation"
            :type="showPasswordConfirmation ? 'text' : 'password'"
            :rules="[(val) => !!val || 'Campo Obbligatorio']"
          >
            <template #prepend>
              <q-icon name="mdi-lock-outline" />
            </template>

            <template #append>
              <q-icon
                :name="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                class="cursor-pointer"
                @click="showPasswordConfirmation = !showPasswordConfirmation"
              />
            </template>
          </BaseInput>
        </div>
      </div>
    </transition>

    <!-- default slot -->
    <slot />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import useVModel from '../../hooks/vModel.js'
import UserRoleSelect from './UserRoleSelect.vue'

export default {
  name: 'UserEditForm',

  components: { UserRoleSelect },

  props: {
    value: {
      type: Object,
    },
    errors: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const { vModel: user } = useVModel(props.value, ctx)

    const isNew = computed(() => props.value.id == 0)

    const showPasswordUpdate = computed(
      () => isNew.value || props.value.password_update
    )
    const showPassword = ref(false)
    const showPasswordConfirmation = ref(false)

    return {
      isNew,
      user,
      showPassword,
      showPasswordConfirmation,
      showPasswordUpdate,
    }
  },
}
</script>
