<template>
  <BaseCard
    multi-section
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <q-list dense class="q-pb-md">
      <template v-for="(section, name) in sections">
        <q-item :key="`user_label${name}`" class="q-mx-sm q-my-xs">
          <q-item-section avatar>
            <q-icon color="primary" :name="section.icon" />
          </q-item-section>

          <q-item-section>
            <div class="flex items-center">
              <span v-html="sectionValue(name)" />
              <q-item-label v-if="!!section.label" caption class="q-ml-sm">
                <q-icon
                  name="fiber_manual_record"
                  style="font-size: 6px"
                  class="q-mr-xs"
                />
                {{ section.label }}
              </q-item-label>
            </div>
          </q-item-section>
        </q-item>
      </template>
    </q-list>

    <BaseBanner
      v-if="!user.active"
      class="q-mx-lg q-mb-lg"
      icon="mdi-information-outline"
      message="Questo account è disattivato"
    />
  </BaseCard>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    user: Object,
    subtitle: {
      type: String,
      defaul: null,
    },
    title: {
      type: String,
      default: 'Utente',
    },
  },
  setup(props) {
    const sections = {
      name: {
        icon: 'mdi-account-circle-outline',
      },
      email: {
        icon: 'mdi-at',
        value(value) {
          return value
            ? `<a class="text-pink" href="mailto:${value}">${value}</a>`
            : '-'
        },
      },
      role: {
        icon: 'mdi-star',
        label: 'Ruolo',
        value(value) {
          return value?.name || 'non definito'
        },
      },
    }
    function sectionValue(name) {
      if (sections[name].value == undefined) return props.user[name] || '-'
      return sections[name].value(props.user[name])
    }
    return {
      sections,
      sectionValue,
    }
  },
}
</script>
