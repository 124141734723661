<template>
  <q-table
    class="wrapped"
    flat
    square
    binary-state-sort
    v-bind="$attrs"
    v-on="$listeners"
    :columns="columns"
  >
    <template #top>
      <UserListFilters
        :value="filters"
        @input="(filters) => $emit('filter', filters)"
      />
    </template>

    <template v-slot:body-cell-active="scope">
      <q-td :props="scope">
        <q-icon
          size="sm"
          :name="
            scope.value
              ? 'mdi-checkbox-marked-circle-outline'
              : 'mdi-radiobox-blank'
          "
          :color="scope.value ? 'positive' : 'grey-1'"
        >
          <q-tooltip>
            {{ scope.value ? 'Attivo' : 'Disattivato' }}
          </q-tooltip>
        </q-icon>
      </q-td>
    </template>

    <template v-slot:body-cell-color="scope">
      <q-td :props="scope">
        <q-icon size="lg" name="mdi-circle" :style="{ color: scope.value }" />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import UserListFilters from './UserListFilters.vue'

export default {
  name: 'UserTableList',

  components: {
    UserListFilters,
  },

  props: {
    filters: {
      type: Object,
    },
  },

  setup() {
    const columns = [
      {
        name: 'active',
        label: 'Attivo',
        field: 'active',
        align: 'center',
        style: 'width:38px',
      },
      {
        name: 'color',
        label: 'Colore',
        field: 'color',
        align: 'center',
        style: 'width:38px',
      },
      {
        name: 'id',
        label: 'ID',
        field: 'id',
        align: 'left',
        style: 'width:38px',
      },
      {
        name: 'name',
        label: 'Nome',
        field: 'name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'email',
        label: 'Email',
        field: 'email',
        align: 'left',
      },
    ]

    return {
      columns,
    }
  },
}
</script>
