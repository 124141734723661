<template>
  <BaseSelect
    ref="select"
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    :options="options"
    :display-value="value.name ? value.name : ''"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <template v-slot:option="scope">
      <q-item v-bind="scope.item.itemProps" v-on="scope.item.itemEvents">
        <q-item-section>
          <q-item-label>{{ scope.item.opt.name }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </BaseSelect>
</template>

<script>
import { useActions, useState } from '../../hooks/store.js'

export default {
  name: 'UserRoleSelect',
  inheritAttrs: false,

  props: {
    value: {
      type: Object,
    },
  },

  setup() {
    const { load: loadRoles } = useActions('role', ['load'])
    const { collection: options } = useState('role', ['collection'])
    loadRoles()

    return {
      options,
    }
  },
}
</script>
